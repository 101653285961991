import React from 'react'
import Link from 'gatsby-link'
import { resolvePath } from '../utils/helpers.js'
import Showdown from 'showdown'
import { graphql} from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import CardRelated from '../components/cardRelated'
import Card from '../components/card'
import Cta from '../components/cta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'


library.add(fat, fab)

const ProductTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  const path = location.pathname
  const converter = new Showdown.Converter()
  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={heroImage?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt="vector image set on a deep blue gradient with light blue circle lines and connecting yellow nodes"
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}     
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>

                {post.frontmatter.openingpara &&
                  <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para pt-4"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
                  />
                }

                <div
                  className="richtext product divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section.map((sections, index) => (
          <section className="bg-light-blue-prods" key={index}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="4" className="pr-5">
                  <div className="section-intro pb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                      {sections.title}
                    </h2>
                    <p className="text-medium text-gray-dark mt-3">
                      {sections.description}
                    </p>

                    {sections.link &&
                      <p>
                        <Link to={resolvePath(sections.link)} className="text-medium-small effect">{sections.linktext}</Link>
                      </p>
                    }
                  </div>
                </MDBCol>

                <MDBCol md="8">
                  <MDBRow>
                    {sections.feature.map((features, indx) => (
                      <MDBCol md="6" className="pb-5 item-prods" key={indx}>
                        <div className="feature-icon">
                          <FontAwesomeIcon icon={['fat', features.icon]} size="3x" />
                          <FontAwesomeIcon icon={['fab', features.icon]} size="3x" />
                        </div>
                        <p className="text-medium text-blue-dark font-w-700">
                          {features.title}
                        </p>
                        <p className="text-medium text-gray-dark">
                          {features.description}
                        </p>
                        {features.link !== null && (
                          <p className="text-small">
                            <Link to={resolvePath(features.link)} className="effect">
                              {features.linktext}
                            </Link>
                          </p>
                        )}
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        ))}

        {post.frontmatter.related && post.frontmatter.related.length >= 1 && (
          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    Related products, solutions and news
                  </h2>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                {post.frontmatter.related.map((relate, index) => {
                  return (
                    <CardRelated
                      key={index}
                      collg="4"
                      colmd="6"
                      height="9rem"
                      type={relate.type}
                      title={relate.title}
                      suite={post.frontmatter.suite}
                      description={relate.description}
                      linktext={relate.linktext}
                      link={relate.link}
                    />
                  )
                })}
              </MDBRow>
            </MDBContainer>
          </section>
        )}

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left pb-5">
                  {post.frontmatter.helpfulresources.title}
                  <span id="helpful-resources" className="anchor">
                    &nbsp;
                  </span>
                </h2>

                {post.frontmatter.helpfulresources.subtitle && (
                  <p className="text-medium">
                    {post.frontmatter.helpfulresources.subtitle}
                  </p>
                )}

                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-3">
                    {post.frontmatter.helpfulresources.helpfulres.map(
                      (helpfulres, index) => (
                        <Card
                          key={index}
                          collg="4"
                          colmd="6"
                          height="10.5rem"
                          title={helpfulres.title}
                          subtitle={helpfulres.subtitle}
                          description={helpfulres.description}
                          image={ helpfulres.image.childImageSharp.gatsbyImageData }
                          alt={helpfulres.alttext}
                          placement={helpfulres.placement}
                          link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                          docs={ helpfulres.docs }
                          titleclass="title-small"
                          descriptionClass="text-card-small py-2"
                        />
                      )
                    )}
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
      </Layout>
    </div>
  )
}
export default ProductTemplate

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "products" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        template
        title
        subtitle
        ctatext
        ctaslug     
        openingpara
        suite
        section {
          title
          description
          linktext
          link
          feature {
            title
            icon
            description
            linktext
            link
          }
        }
        related {
          type
          title
          description
          linktext
          link
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
            docs {
              description
              document {
                publicURL
              }
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`